import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useAccess } from "./access";

interface Token {
  permissions: string[];
}

export default function usePermissions(): string[] {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { isAuthenticated, getOriginToken } = useAccess();

  useEffect(() => {
    async function decodeToken() {
      const token = jwt_decode(await getOriginToken()) as Token;
      setPermissions(token.permissions);
    }

    if (isAuthenticated) {
      decodeToken();
    }
  }, [isAuthenticated]);

  return permissions;
}
