import { useAccess } from "./access";

export const LoginButton = () => {
  const { login } = useAccess();

  return (
    <button
      onClick={() => login()}
      className="w-full h-20 mb-4 rounded-xl text-3xl bg-blue-600 text-white focus:outline-none focus:ring-4"
    >
      Log In
    </button>
  );
};

export const LogoutButton = () => {
  const { logout } = useAccess();

  return (
    <button
      onClick={() => logout({ returnTo: window.location.href })}
      className="w-full h-20 mb-4 rounded-xl text-3xl bg-red-500 text-white focus:outline-none focus:ring-4 ring-red-300"
    >
      Log Out
    </button>
  );
};
