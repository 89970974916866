import React, { useContext, useEffect, useReducer } from "react";
import * as Cookies from "es-cookie";
import jwt_decode from "jwt-decode";

interface AccessJWT {
  aud: string[];
  country: string;
  email: string;
  exp: number;
  iat: number;
  identity_nonce: string;
  iss: string;
  nbf: number;
  sub: string;
  type: string;
}

export interface User {
  email: string;
}

interface AccessContext {
  user: User | undefined;
  isAuthenticated: boolean;
  login: () => void;
  logout: ({ returnTo }?: { returnTo?: string }) => void;
  getOriginToken: () => Promise<string>;
}

function logout({ returnTo }: { returnTo?: string } = {}) {
  const url = new URL("/cdn-cgi/access/logout", window.location.origin);

  if (returnTo !== undefined) {
    const params = url.searchParams;
    params.append("returnTo", returnTo);
  }

  window.location.href = url.toString();
}

function login() {
  window.location.href = "/login"; // lmao
}

async function getOriginToken(): Promise<string> {
  const resp = await (await fetch("/exchange-access-token")).json();
  return resp.token as string;
}

const initialContext: AccessContext = {
  user: undefined,
  isAuthenticated: false,
  login,
  logout,
  getOriginToken,
};

type Action =
  | { type: "IS_AUTHENTICATED"; isAuthenticated: boolean }
  | { type: "USER"; user: User };

const context = React.createContext(initialContext);

function reducer(state: AccessContext, action: Action): AccessContext {
  switch (action.type) {
    case "IS_AUTHENTICATED": {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    }
    case "USER": {
      return {
        ...state,
        user: action.user,
      };
    }
  }
}

export function AccessProvider({ children }: { children?: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialContext);

  useEffect(() => {
    const accessCookie = Cookies.get("CF_Authorization");
    if (accessCookie !== undefined) {
      const decoded = jwt_decode<AccessJWT>(accessCookie);
      dispatch({ type: "IS_AUTHENTICATED", isAuthenticated: true });
      dispatch({ type: "USER", user: { email: decoded.email } });
    }
  }, []);

  return <context.Provider value={state}>{children}</context.Provider>;
}

export function useAccess() {
  return useContext(context);
}
